/**
 * Search results page
 */

// Import statements
import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import { useFlexSearch } from 'react-use-flexsearch'
import {useState, useEffect} from "react";
import "../styles/files/search.scss"

import Card from "../components/common/card"
import Layout from "../components/layout"
import Seo from "../components/common/seo"
import HomeHeader from "../components/home/home-header"

/**
 * Define the RecipePostTemplate constant
 * @returns {JSX.Element}
 * @constructor
 */
const Search = () => {

    // ----------------------
    // BUILD TIME DATA FETCHING USING GRAPHQL
    // ----------------------
    const queryData = useStaticQuery(graphql`
      query {
        localSearchPosts {
          index
          store
        }
        localSearchRecipes {
          index
          store
        }
        localSearchHotspots {
          index
          store
        }
      }
    `)

    // get all indexes & stores
    const index_posts = queryData.localSearchPosts.index
    const store_posts = queryData.localSearchPosts.store
    const index_recipes = queryData.localSearchRecipes.index
    const store_recipes = queryData.localSearchRecipes.store
    const index_hotspots = queryData.localSearchHotspots.index
    const store_hotspots = queryData.localSearchHotspots.store

    // get query
    const [query, setQuery] = useState('')
    useEffect(() => {
        if(typeof window !==`undefined`){
            const urlParams = new URLSearchParams( window.location.search );
            const queryString = urlParams.get("s")
            if(queryString){
                setQuery(queryString);
            }
        }
    }, []);

    // search for the query in the index, return right results and add those to an array
    const results_posts = useFlexSearch(query, index_posts, store_posts );
    const results_recipes = useFlexSearch(query, index_recipes, store_recipes );
    const results_hotspots = useFlexSearch(query, index_hotspots, store_hotspots );

    // start with empty array
    const results = [
        results_posts,
        results_recipes,
        results_hotspots
    ];

    // sort the array
    results.sort(function(a, b) {
        return b.length - a.length;
    });

    // return the information
    return (
        <Layout isHomePage>

            <Seo title="All posts" />

            <HomeHeader title={`${calculateTotalLines( results )} zoekresultaten gevonden voor '${query}'`} id="home-header-collection" />
            {processResults( results, query )}

        </Layout>
    )
}

/**
 * Export the Search variable
 */
export default Search

/**
 * Calculate the total lines
 * @param results
 * @param amount_of_results
 * @returns {number}
 */
function calculateTotalLines( results ) {

    // keep track of the total amount of results
    let total_results = 0;

    // loop through the results and determine the total results
    for( let i = 0; i < results.length; i++ ) {
        total_results += results[i].length;
    }

    // return the total results
    return total_results;
}

/**
 * Loop through the array of results, and call displayResultsForPostType() for each of them
 * @param collection
 * @param query
 * @returns {*}
 */
function processResults( collection, query ) {

    // return the results
    return (

        // for each collection (posts, recipes, hotspots) create results
        collection.map((results, index) => {
            return displayResultsForPostType( results, query, index );
        })
    )
}

/**
 * Display the results
 * @param results
 * @param query
 * @returns {*|string}
 */
function displayResultsForPostType ( results, query, index ) {

    // return results
    return (

        <div className="card-wrapper-search" key={`card-wrapper-outer-'${index}'`}>

            { results.length > 0 ? getTitle( results ) : '' }
            <div className="row g-4 g-sm-2 g-md-4" id="card-wrapper" key={`card-wrapper-inner-'${index}'`}>

            {
            // check if all the data is filled in
            (results.length > 0 ?

                    // for each item, create a search result item
                    results.map((post, index) => {

                        // set the right image so we can use a normal card
                        post.featuredImage = post.cardResultImage;

                        return ( <Card post={post} index={index} key={index} /> )
                    })

                    // otherwise
                    :

                    // return no results
                    ''
            )
            }

            </div>

        </div>
    )
}


function getTitle( results ) {

    // get the uri of the first post
    const uri = results[0].uri

    // define the title
    let title = 'Berichten';

    // overwrite the title
    if ( uri.includes( 'recipe' ) ) {
        title = 'Recepten';
    } else if ( uri.includes( 'hotspot' )  ) {
        title = 'Hotspots';
    }

    // return the title
    return (
            <h3>{title}</h3>

    )
}