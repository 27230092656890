/**
 * HomeHeader component to display title on the homepage
 */

// Import statements
import React from "react"

/**
 * Define the HomeHeader constant
 * @param title
 * @param id
 * @returns {JSX.Element}
 * @constructor
 */
const HomeHeader = ({title, id}) => {

    return (
        <div className="row" id={id}>
            <div className="col col-no-padding">
                <h2>{title}</h2>
            </div>
        </div>
    )
}

/**
 * Export the HomeHeader
 */
export default HomeHeader
